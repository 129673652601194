require('intersection-observer');

// 交差監視対象
let observeTargets = document.querySelectorAll(".js-observeTarget");

const options = {
    root: null,
    rootMargin: "-30% 0px -70%",
    threshold: 0
};

const observer = new IntersectionObserver(doWhenIntersect, options);

observeTargets = Array.prototype.slice.call(observeTargets, 0); // IE11対応
observeTargets.forEach( function(target) {
    observer.observe(target);
});


/**
 * 交差時に呼び出される関数
 * @param entries
 */
function doWhenIntersect(entries) {

  entries = Array.prototype.slice.call(entries, 0); // IE11対応
  entries.forEach( function(entry) {
    if (entry.isIntersecting) {
        activateCurrentNav(entry.target);
    }
  });
}

/**
 * ナビの色を変える関数
 * @param element
 */
function activateCurrentNav(intersectingElement) {

    // is-currentクラスを全てクリア
    const currentActiveNav = document.querySelector(".js-activateCurrentNav.is-current");
    if (currentActiveNav !== null) {
        currentActiveNav.classList.remove("is-current");
    }

    // 交差している要素のID取得
    let intersectingSection = intersectingElement.getAttribute("id");

    // IDと一致するナビを特定
    let targetHeaderNav = document.querySelector('[data-nav="' + intersectingSection + '"]');

    // is-currentクラスを付与
    if(targetHeaderNav !== null) {

        targetHeaderNav.classList.add("is-current");
    }
}