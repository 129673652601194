$(function () {

    const smoothScroll = (function () {

        const TRIGGER = $('a[href^="#"]');

        return {

            init: function () {

                if (TRIGGER.length) {

                    this.setEvent();
                }
            },

            setEvent: function () {

                let headerHeight = $('.js-headerHeight').outerHeight();
                let adjust = headerHeight + 50;
                let speed = 500;
                let urlHash = location.hash;

                if(window.matchMedia('screen and (max-width: 768px)').matches) {

                    adjust = headerHeight + 30;
                }

                // IDつきのURLかどうか
                if (urlHash) {

                    $('body,html').stop().scrollTop(0);

                    setTimeout(function () {
                        let target = $(urlHash);
                        let position = target.offset().top - headerHeight;
                        $('body,html').stop().animate({ scrollTop: position }, speed);
                    }, 100);
                }

                TRIGGER.on('click', function (e) {

                    e.preventDefault();

                    let href = $(this).attr("href");
                    let target = $(href == "#" || href == "" ? 'html' : href);
                    let position = target.offset().top - adjust;

                    $('html,body').animate({ scrollTop: position }, speed, 'swing');

                    return false;
                });

            }
        }
    })();

    smoothScroll.init();
});