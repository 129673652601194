$(function () {

    const hamburger = (function () {

        const TRIGGER = $('.js-hamburgerTrigger');

        return {

            init: function() {

                if (TRIGGER.length) {

                    this.setEvent();
                }
            },

            setEvent: function() {

                $('.js-hamburgerTrigger, .js-hamburgerNavLink').on('click', function () {

                    $('.js-hamburgerTarget').toggleClass('is-active');
                });
            }
        }
    })();

    hamburger.init();
});
